/* Style Core */
/* Necessary in React for font */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:root{
  --background: #F0EDF0;
  --primary: #000000;
  --highlight: #7D6F7D;
  --highlightFaded: rgba(125, 111, 125, 0.5);
}

html {
  font-size: 15px;  /* root font size scales to viewport height */
  -ms-overflow-style: none;
  scrollbar-width: none;

}

button {
  font-family: 'Nunito Sans';
  color: var(--background);
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--highlight);
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 0.25rem 0.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button:hover {
  opacity: 0.75;
}

h1 {
  color: var(--primary);
  font-size: 2.5rem ; /* scale depending on viewport height
  letter-spacing: 0.03125rem; /* 0.5px */
  font-weight: 600;
  text-shadow: 1.25px 1.25px 0.6px grey;
  display: block;
}

h2 {
  color: var(--primary);
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
  text-shadow: 1.25px 1.25px 0.75px grey;
  display: block;
}

h3 {
  color: var(--primary);
  font-size: 1.125rem; /* 18px */
  font-weight: 300;
  display: block;
}

h4 {
  color: var(--highlight);
  font-size: 1.0rem; /* 16px */
  font-weight: 300;
}

h5 {
  color: var(--highlight);
  font-size: 0.75rem; /* 12px */
  font-weight: 200;
}

h6 {
  color: var(--highlight);
  font-size: 0.5rem; /* 8px */
  font-weight: 150;
}

ul {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

p {
  display: block;
  font-size: 1.125rem; /* 18px */
}

a {
  color: var(--highlight);
  font-weight: 600; /* slightly darker */
  text-decoration: none;
  text-shadow: 0.2px 0.2px 0px grey;
}

a:hover {
  color: var(--highlight);
  opacity: 0.5;
  text-decoration: none;
}

li {
  list-style-type: square;
  font-size: 1.125rem;
}

/* Containers */
.backgroundContainer { /* Master container in main.js, setting background and height universally */
  color: var(--primary);
  background-color: var(--background);
}

.appContainer {
  margin-left: auto;
  margin-right: auto;
  width: 56%;
}

/* in App.js, header -> main -> footer where max-height is 10vh, 80vh, 10vh respectively
  -ms-overflow-style: none;  Hide scrollbar for IE and Edge
  scrollbar-width: none;   IE and Edge
*/

.mainContainer {
  box-sizing: border-box;  /* border & padding now included when calculating element height, therfore 20vh is constant */
  min-height: 80vh;
  -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hides scrollbar in Firefox */

}

/* Hide scrollbar for Chrome, Safari and Opera */
.mainContainer::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.fullPageContainer {
  box-sizing: border-box;  /* border & padding now included when calculating element height, therfore 20vh is constant */
  height: 80vh;
}

.fullPageContainer::-webkit-scrollbar {
  display: none;
}

/* generic content block */
.bodyContainer {
  padding: 0 1rem;
  width: 100%;
}

.buttonContainer {
  padding-bottom: 1rem;
}

.centered {
  text-align: center;
}

.learnMore {
  color: var(--highlight);
  font-size: 1.0rem;
  font-weight: 200;
}

.subscript-text {
  font-weight: 200;
  font-size: 0.7rem;
}

/* Mobile Styling for Containers */
@media only screen and (max-width: 800px) {
  html {
    font-size: 16px;
  }

  h1 {
    font-size: 2rem;
  }

  .appContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bodyContainer {
    width: 90%;
  }

  #Products {
    text-align: center;
  }
}