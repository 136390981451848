.footerContainer {
  box-sizing: border-box;  /* border & padding now included when calculating element height, therfore 20vh is constant */
  color: var(--highlight);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 10vh;
  overflow-y: hidden;
  text-align: right;
  border-top: 1px var(--highlightFaded) solid;
  padding-bottom: 1rem;
  /* border: 0.2px black solid; */
}

/* Icons */
.social-links i {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}


@media only screen and (max-width: 800px) {
  .footerContainer {
    text-align: center;
  }
}