.image-container {
  background-color: black;
  position: relative;
  width: 22rem;
  height: 22rem;
  box-shadow: 3px 4px 2px 0 rgba(0, 0, 0, 0.2);
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.icon-container > * {
  font-size: 2.5rem;
}

.exit-icon {
  color: var(--background);
  float: left;
  text-shadow: 0 0 2px var(--background);
}

.exit-container {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 1rem 0 0 1rem;
}

.exit-container:hover {
  cursor: pointer;
  opacity: 0.75;
}

.html-audio-container {
  display: flex;
  justify-content: center;
  width: 80%;
  padding-top: 0.5rem;
}


audio {
  /* background-color: var(--background); */
  border-radius: 10px;
  width: 80%;
  height: 28px;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

.middle-overlay {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.50);
  color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.bottom-overlay {
  z-index: 50;
  position: absolute;
  top: 71%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 29%;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 3px 4px 2px 0 rgba(0, 0, 0, 0.2);
  color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.overlay > * {
  color: var(--background);
}

.bottom-overlay > * {
  color: var(--background);
}

.top-overlay {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background);
  position: absolute;
  top: 0;
  height: 8%;
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.8);
  font-style: italic;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
}

.main-content {
  padding: 1rem;
  text-align: center;
}

.songTitle {
  font-weight: bold;
  text-transform: uppercase;
}

.buttonText {
  font-weight: 600;
  color: var(--background);
  font-size: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.opb {
  font-style: italic;
}

.arrangement-desc {
  text-align: center;
  font-size: 0.9rem;
  /* max-height: 88%; */
}

.action-button {
  font-size: 1.125rem;
  font-weight: 200;
  background-color: var(--highlight);
  border: 0.1px solid var(--primary);
  border-radius: 5px;
  padding: 0.15rem 0.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.play-icon {
  color: white;
  font-size: 4rem;
  text-shadow: 2px 2px 4px rgb(0, 0, 0, 0.5);
}

.play-icon:hover {
  cursor: pointer;
  opacity: 0.75;
}

.parts-text {
  color: var(--background);
  font-weight: 500;
  opacity: 0.65;
}
