.profileImageContainer {
  /* border: 2px black solid; */
  /* height: calc(8rem + 9vh); 176px + 10% viewport height to pad scaling */
  padding: 1.5rem 0;
}

.profileImage {
  /* border-radius: 50%; */
  width: 15rem;
  border-radius: 7px;
  box-shadow: 1px 2px 5px grey;
}

#superscript {
  color: var(--highlight);
  font-size: 1rem;
  margin-bottom: -0.4rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homeContainer {
  display: flex;
  flex-direction: column;
}

.description {
  width: 75%;
}

/* Mobile Styling for Containers */
@media only screen and (max-width: 800px) {
  .profileImageContainer {
    text-align: center;
  }

  .homeContainer {
    align-items: center;
    text-align: center;
  }

  .description {
    width: 100%;
  }
}