.actionsContainer {
	display: flex;
	justify-content: flex-start;
}

/* Mobile Styling for Containers */
@media only screen and (max-width: 800px) {
	.bodyContainer {
		text-align: left;
	}

	.actionsContainer {
		display: flex;
		justify-content: flex-start;
	}
}