.headerContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 10vh;
  overflow-y: hidden;
  border-bottom: 1px var(--highlightFaded) solid;
  padding-top: 1.5rem;
}

.icon {
  color: var(--highlight);
}

.icon:hover {
  cursor: pointer;
  opacity: 0.75;
}

.navLink {
  margin: 0 0.5rem;
}

#leftContainer {
  float: left;
}

#rightContainer {
  float: right;
}


/* Mobile Styling for Containers */
@media only screen and (max-width: 800px) {
  .headerContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }


  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .full-screen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    /* Default state: Hidden */
    opacity: 0;
    pointer-events: none; /* Prevent interactions when hidden */
  }

  /* Show modal with animation */
  .full-screen-modal.open {
    animation: fadeIn 0.2s ease-out forwards;
    pointer-events: auto; /* Enable interactions */
  }

  /* Hide modal with animation */
  /* TODO: Can't get modal to fade out without also having it flash initially on page load */
  .full-screen-modal.closed {
    /* animation: fadeOut 0.2s ease-in forwards; */
    pointer-events: none;
  }
}